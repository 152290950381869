import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/_modules/dashboard/_service/authentication.service';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isLoggedIn = this.authService.isUserSignedIn();
    if (isLoggedIn) {
        const ageroAuthToken = this.authService.getRawIdToken();
        request = request.clone({
          headers: request.headers.set('Authorization', 'Bearer ' + ageroAuthToken),
        });

        request = request.clone({
        headers: request.headers.set('Accept', 'application/json'),
      });
    }
    return next.handle(request);
  }
}
