import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ExtensionListComponent } from './tabs/extension-list/extension-list.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IgxIconModule, IgxPaginatorModule, IgxTabsModule, IgxGridModule,
  IgxCardModule, IgxInputGroupModule, IgxToastModule, IgxTimePickerModule, IgxDatePickerModule, IgxSwitchModule, IgxButtonModule, IgxToggleModule } from 'igniteui-angular';
import { AgentLogComponent } from './tabs/activity-log/activity-log.component';
import { LogExtensionComponent } from './tabs/log-extension/log-extension.component';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { CallbackComponent } from './_modules/callback/callback.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IdentityGuard } from './_modules/dashboard/_guards/identity.guard';
import { LogoutGuard } from './_modules/dashboard/_guards/logout.guard';
import { ClipboardModule, ClipboardService } from 'ngx-clipboard';
// this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MatDialogModule } from '@angular/material/dialog';
import { SessionTimeoutConfirmationDialogComponent } from './_modules/dashboard/session-timeout/session-timeout-confirmation-dialog/session-timeout-confirmation-dialog.component';
import { UserinactivityService } from './_modules/dashboard/_service/userinactivity.service';
import { AuthService } from './_modules/dashboard/_service/authentication.service';
import { LogoutComponent } from './_modules/logout/logout.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { ExtensionListService } from './service/extension-list/extension-list.service';
import { httpInterceptorProviders } from './shared/_interceptor';
import { APIInterceptor } from './shared/_interceptor/API.Inteceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    AppComponent,
    /* ExtensionListComponent,
    AgentLogComponent,
    LogExtensionComponent, */
    CallbackComponent,
    LogoutComponent,
    HeaderComponent,
    SessionTimeoutConfirmationDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    IgxIconModule,
    IgxTabsModule,
    IgxGridModule,
    IgxCardModule,
    HttpClientModule,
    ClipboardModule,
    FormsModule,
    IgxInputGroupModule,
    IgxPaginatorModule,
    IgxToastModule,
    NgIdleKeepaliveModule.forRoot(),
    MatDialogModule,
    NgbModule,
    IgxTimePickerModule,
    IgxDatePickerModule,
    IgxSwitchModule
  ],
  entryComponents: [SessionTimeoutConfirmationDialogComponent],
  providers: [
    ClipboardService,
    DatePipe,
    ExtensionListService,
    httpInterceptorProviders,
    IdentityGuard,
    LogoutGuard,
    AuthService,
    UserinactivityService,
    { provide: HTTP_INTERCEPTORS, useClass: APIInterceptor, multi: true } ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
