import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'extensionmanagement-ui';
  getExtensionHasaccess = false;
  extensionListHasaccess = false;
  activityLogHasaccess = false;
  accessToken: any;
  constructor() {
  }

}
