import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {CodeAuth} from 'agero-js-identity';
import { environment } from 'src/environments/environment';
import logger from 'src/app/shared/logging';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  constructor( ) {}
  static isCreating = false;
  static instance: AuthService ;
  baseUrl = window.location.origin + '/';
  debugger;
  public codeAuth = new CodeAuth({
    clientId: environment.ageroAuthConfig.clientId,
    appWebDomain: environment.ageroAuthConfig.appWebDomain,
    redirectUriSignIn:
      this.baseUrl + environment.ageroAuthConfig.redirectUriSignIn,
    redirectUriSignOut:
      this.baseUrl + environment.ageroAuthConfig.redirectUriSignOut,
    apiFullUrl: environment.ageroAuthConfig.apiFullUrl,
    refreshTimeoutOffset: environment.ageroAuthConfig.refreshTimeoutOffset,
    activityTimeout: environment.ageroAuthConfig.activityTimeout,
    useMultiTenantApi: environment.ageroAuthConfig.useMultiTenantApi,
    loginCallback: (value) => {
      const logData = {
        level: 'Info',
        message: 'Login Succsessful',
        logDescription: value
          ? JSON.stringify(value)
          : this.codeAuth.getDecodedIdToken(),
        logStory: 'User signed into Agent Extension Management successfully'
      };
      logger.info(logData);
    },
    refreshCallback: (value) => {
      // console.log("Token refreshed");
      const logData = {
        level: 'Info',
        message: 'I refreshed my token! callback',
        logDescription: value
          ? JSON.stringify(value)
          : this.codeAuth.getDecodedIdToken(),
        logStory: 'User succesfully refreshed the token'
      };
      logger.info(logData);
    },
    signOutCallback: (value) => {
      const logData = {
        level: 'Info',
        message: 'I logged out! callback',
        logDescription: value
          ? JSON.stringify(value)
          : this.codeAuth.getDecodedIdToken(),
        logStory: 'User logged out successfully',
      };
      logger.info(logData);
      console.log('I logged out!' + value);
      localStorage.clear();
    },
    errorCallback: (err) => {
      console.log('Error occurred!' + err);
      const logData = {
        level: 'Critical',
        message: 'Error occured',
        logDescription: 'error is:' + err ? JSON.stringify(err) : 'NA',
        logStory: 'Error occured when user tried to login',
      };
      logger.error(logData);
    },
  });
  static getInstance() {
    if (AuthService.instance == null) {
      AuthService.isCreating = true;
      AuthService.instance = new AuthService ();
      AuthService.isCreating = false;
    }
    return AuthService.instance;
  }

  getSession() {
    return this.codeAuth.getSession();
  }
  parseRedirect(callBack: (Function) => void) {
    this.codeAuth.parseRedirect(callBack);
  }
  login() {
    this.codeAuth.getSession();
  }
  signOut() {
    this.codeAuth.signOut();
  }
  isUserSignedIn(): boolean {
    return this.codeAuth.isUserSignedIn();
  }
  getDecodedIdToken() {
    const decodedToken = this.codeAuth.getDecodedIdToken();
    return decodedToken;
  }

  getRawIdToken() {
    const idToken = this.codeAuth.getRawIdToken();
    return idToken;
  }
  refreshToken(refreshCallBack: any) {
    return this.codeAuth.refreshToken(refreshCallBack);
  }
  activityTimer() {
    return this.codeAuth.resetActivityTimer();
  }

  checkLogin() {
    if (this.isUserSignedIn()) {
      const logData = {
        level: 'Info',
        message: 'Check for isUserSignedIn',
        logDescription: 'isUserSignedIn is successful - true',
        logStory: 'Checking user signed in status : User is already signed in'
      };
      logger.info(logData);
      return true;
    } else {
      const logData = {
        level: 'Info',
        message: 'Check for isUserSignedIn',
        logDescription:
          'isUserSignedIn is successful - false, that leads to user to login page',
        logStory: 'Checking user signed in status : User is not signed in'
      };
      logger.info(logData);
      this.login();
    }
  }

}
