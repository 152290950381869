import { HttpHeaders } from '@angular/common/http';

export const environment = {
  production: false,
  envName: 'Local',
  version: '1.0',
  withSSO: true,
  logging: true,
  popupTimer: 300, // 5 minute before
  splunk: {
    URL: 'https://agero-stg.apigee.net/rsahelpapi/services/collector/event',
    apiKey: 'lINtQ9dWRSXgOtZWcGEIbndpi084A24i'
  },

  api: {
    // url: 'http://localhost:5000/'
    url: 'https://extension-management.rsa-nonprod.ageroapi.net/qa/'
  },
  adminGroup: 'Agero_VIMS_AgeroAdmin',
  ageroAuthConfig: {
    clientId: '76lqeat8b2chl01pkb5723ep34',
    useMultiTenantApi: true,
    appWebDomain: 'login.nonprod.oauth.agero.com',
    redirectUriSignIn: 'callback.html',
    redirectUriSignOut: 'logout.html',
    apiFullUrl: 'https://nonprod.api.oauth.agero.com/multi-nonprod/api/v1',
    refreshTimeoutOffset: 10 * 60,
    // How many seconds of inactivity before the user is signed out.  If not included, defaults to 10 minutes
    activityTimeout: 30 * 60,
  },
    DomainURL: 'http://localhost:3000',
    // SocketConnectionURL: 'wss://hp3p0tiyuj.execute-api.us-east-1.amazonaws.com/dev?header=',
};
