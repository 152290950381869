import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from 'src/app/config/app.config';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ExtensionListService {

  constructor(private httpClient: HttpClient) { }

  /* loads both busy and available extensions */
  loadExtensionList(): Observable<any> {
    const GET_EXTENSION_LIST = environment.api.url + 'extensions/all';
    return this.httpClient.get(GET_EXTENSION_LIST);
  }

  disableExtension(extension): Observable<any> {
    const DISABLE_EXTENSION = environment.api.url + `extension/${extension}/disable`;
    return this.httpClient.post<any>(DISABLE_EXTENSION, {});
  }

  importExtension(request): Observable<any> {
    // const import_API = 'https://extension-management.rsa-nonprod.ageroapi.net/qa/extensions/import';
    const IMPORT_EXTENSION_LIST = environment.api.url + 'extensions/import';
    return this.httpClient.post<any>(IMPORT_EXTENSION_LIST, request);
  }

  enableExtension(extension): Observable<any> {
    const ENABLE_EXTENSION = environment.api.url + `extension/${extension}/enable`;
    return this.httpClient.post<any>(ENABLE_EXTENSION, {});
  }
}
