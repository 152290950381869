import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CallbackComponent } from './_modules/callback/callback.component';
import { LogoutComponent } from './_modules/logout/logout.component';


const routes: Routes = [
  { path: '', redirectTo: '/dashboard/home', pathMatch: 'full' },
  { path: 'callback.html', component: CallbackComponent },
  {
    path: 'dashboard',
    loadChildren: './_modules/dashboard/dashboard.module#DashboardModule',
    data: { meta: { title: 'Dashboard' } },
  },
  { path: 'logout.html', component: LogoutComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
