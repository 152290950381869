import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { tap } from 'rxjs/operators';
import { AuthService } from '../../_modules/dashboard/_service/authentication.service';
// Environment
import { environment } from '../../../environments/environment';
import logger from '../logging';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
  ) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Log only if its set in the environment

    if (environment.logging) {

      if (!request.headers.has('noLog')) {

        let eventAction = '';
        console.log('in the logger');
        // getting the user action that triggered the API request
        if (request.headers.has('userTriggerEvent')) {
          eventAction = request.headers.get('userTriggerEvent');
          request = request.clone({
            headers: request.headers.delete('userTriggerEvent')
          });
        }
        eventAction =
          eventAction +
          ' triggered the api request - url : ' +
          request.url +
          ', Body: ' +
          JSON.stringify(request.body);
        let logData = {
          level: 'Info',
          message: 'API Request',
          logDescription: eventAction,
          logStory : 'Triggered an api request to url : ' +  request.url,
        };

        logger.info(logData);
        /*send click event to google analytics*/

        return next.handle(request).pipe(
          tap(
            (event: HttpEvent<any>) => {
              if (event instanceof HttpResponse) {
                logData = {
                  level: 'Info',
                  message: 'Successful API request',
                  logDescription: JSON.stringify(event),
                  logStory : 'Successful api request - url : ' +  request.url,
                };
                logger.info(logData);
              }
            },
            (err: any) => {
              if (err instanceof HttpErrorResponse) {
                // const programCode = localStorage.getItem('currentProgramCode');
                const decodedUserCode = this.authService.getDecodedIdToken().programCode;
                if (err.status === 401 || err.status === 403) {
                  logData = {
                    level: 'Critical',
                    message: 'Failed API request unauthorized',
                    logDescription: `Failed API request unauthorized for ${decodedUserCode}:` + JSON.stringify(err),
                    logStory : 'Failed API request unauthorized - url : ' +  request.url + ' , User entered Details: ' + JSON.stringify(request.body),
                  };
                } else {
                  logData = {
                    level: 'Error',
                    message: 'Failed API request',
                    logDescription: `Failed API request : ` + JSON.stringify(err),
                    logStory : 'Failed API request - url : ' +  request.url + ' , User entered Details: ' + JSON.stringify(request.body),
                  };
                }
                logger.error(logData);
              }
            }
          )
        );
      }
    }
  }
}
