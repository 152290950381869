import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/_modules/dashboard/_service/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  userDetails: { fullName: string; email: any };
  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.loadUserDetails();
  }
  loadUserDetails() {
    const tokenData = this.authService.getDecodedIdToken();
    this.userDetails = {
      fullName: tokenData.given_name + ' ' + tokenData.family_name,
      email: tokenData.email,
    };
  }
  logOut(): void {
    this.authService.signOut();
  }

}
