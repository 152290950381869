import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../_service/authentication.service';
import { MatDialogRef} from '@angular/material';
import logger from 'src/app/shared/logging';
// import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-session-timeout-confirmation-dialog',
  templateUrl: './session-timeout-confirmation-dialog.component.html',
  styleUrls: ['./session-timeout-confirmation-dialog.component.scss']
})
export class SessionTimeoutConfirmationDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SessionTimeoutConfirmationDialogComponent>,
    private authService: AuthService,
    private router: Router
  ) {}
  ngOnInit() {
    const tokenData = this.authService.getDecodedIdToken();
    const reportUrl = this.router.url;
    const logData = {
      level: 'Info',
      message: `User is inactive for 25mins , user details - Email:${tokenData.email}, First Name:${tokenData.given_name},
      Last Name:${tokenData.family_name}`,
      logDescription: 'url is:' + reportUrl,
      logStory:
        'Session timeout popup is displayed as the user is inactive for more than 25 mins'
    };
    logger.info(logData);
  }

}
