import { Injectable } from '@angular/core';
// import { MatDialog } from '@angular/material';
import { environment } from 'src/environments/environment';
import { SessionTimeoutConfirmationDialogComponent } from '../session-timeout/session-timeout-confirmation-dialog/session-timeout-confirmation-dialog.component';
import { AuthService } from './authentication.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class UserinactivityService {

  idleState = 'Not started.';
  timedOut = false;
  constructor(
    public dialog: MatDialog,
    private idle: Idle,
    private authService: AuthService
  ) {}

  public userActivity() {
    // debugger;
    this.idle.setIdle(2); // in seconds
    this.idle.setTimeout(environment.ageroAuthConfig.activityTimeout);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      this.authService.activityTimer();
    });

    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      // console.log(this.idleState);
    });

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!';
      // console.log(this.idleState)
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      if (countdown === environment.popupTimer) {
        this.showSessionTimeoutConfirmationPopup();
      }

      if (countdown <= 40) {
        this.removeApplicationData();
      }
    });

    this.reset();
  }
  private reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
  private showSessionTimeoutConfirmationPopup(): void {
    const dialogRef = this.dialog.open(
      SessionTimeoutConfirmationDialogComponent,
      {
        panelClass: 'screen-dialog',
        autoFocus: false,
      }
    );
  }

  removeApplicationData() {
    localStorage.removeItem('socketConnected');
    localStorage.removeItem('socketConnectedExtn');
    localStorage.removeItem('isAgentLoggedIn');
    localStorage.removeItem('socketConnectedOnInit');
    setTimeout(() => {
      this.authService.signOut();
    }, 900);
  }
}
