import { Component, OnInit } from '@angular/core';
import { AuthService } from '../dashboard/_service/authentication.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import logger from 'src/app/shared/logging';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
})
export class CallbackComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.authorizeUser();
  }

  private authorizeUser() {
    if (this.authService.isUserSignedIn()) {
      /*prevent the user to goback to callback page after succesfull login*/
      const logData = {
        level: 'Info',
        message: 'User is signIn already',
        logDescription: 'So Redirect to dashboard',
        logStory: 'As user is already logged in and redirecting to the dashboard'
      };
      logger.info(logData);
      this.router.navigateByUrl('/dashboard/home');
    } else {
      this.generateSession();
      //  debugger;
    }
  }

  private generateSession() {
    /* generate session for user */
    const callbackCode = this.route.snapshot.queryParamMap.get('code');
    this.authService.parseRedirect(() => {
      const logData = {
        level: 'Info',
        message: 'parseRedirect callback happend',
        logDescription: 'callback code:' + callbackCode,
        logStory: 'After generating session, redirecting to Agent Extension Management dashboard'
      };
      logger.info(logData);
      console.log('parse redirect completed');
      const token = this.authService.getRawIdToken();
      if (token) {
        this.router.navigateByUrl('/dashboard/home');
      }
    });
  }

}
