import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { createLogger } from 'winston';
import * as TransportStream from 'winston-transport';
import axios from 'axios';
import { AuthService } from '../_modules/dashboard/_service/authentication.service';

const levels = {
  error: 0,
  warn: 1,
  info: 2,
  critical: 3
};

export class ApiTransport extends TransportStream {
  axios;
  constructor(opts) {
    super(opts);
  }

  corelationID: any = new Date().valueOf() + '' + Math.floor(100000 + Math.random() * 900000);

  log(logData, callback) {
    let options: any = null;
    if (AuthService.getInstance().isUserSignedIn()) {
      options = {
        headers: {
          Authorization: 'Bearer ' + AuthService.getInstance().getRawIdToken()
        }
      };
    }
    axios.post(environment.api.url + 'log', {
      level: logData.level,
      message: logData.message,
      extra: {
        logLevel: logData.level,
        application: 'Agent Extension Management',
        environment: environment.envName,
        correlationid: this.corelationID,
        logDescription: logData.logDescription,
        logStory: logData.logStory,
        deviceInfo: {
          platform: navigator.platform,
          userAgent: navigator.userAgent
        }
      }
    }, options);
    callback();
  }
}


// Using transport
const transport = new ApiTransport({});

// Create a logger and consume an instance of your transport
const logger = createLogger({
  levels,
  transports: [transport]
});

export default logger;
